@tailwind base;



@font-face {
  font-family: "SuzukiRegular";
  src: url("../public/assets/fonts/SuzukiPRORegular.otf");
}

@font-face {
  font-family: "SuzukiBold";
  src: url("../public/assets/fonts/SuzukiPROBold.otf");
}

@font-face {
  font-family: "SuzukiHeadline";
  src: url("../public/assets/fonts/SuzukiPROHeadline.otf");
}

@tailwind components;
@tailwind utilities;

@layer components {
  html,
  body {
    /* scroll-behavior: smooth; */
    padding: 0;
    margin: 0;
    font-family: SuzukiRegular, sans-serif;
  }

  :root {
    touch-action: pan-x pan-y;
    height: 100%
  }
  

  .container-fluid {
    @apply w-full mx-auto max-w-screen-4xl;
  }

  .active-bg{
    @apply bg-primary-blue
  }

  .container {
    @apply w-[90%] sm:w-[96%] md:w-[96%] lg:w-[96%] xl:w-[100%] 2xl:w-[90%];
  }

  .long-desc {
    @apply w-[80%] sm:w-[80%] md:w-[60%] lg:w-[60%] xl:w-[60%] 2xl:w-[60%];
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-2 font-suzukiBold;
  }
  h2 {
    @apply text-heading;
  }
  .compareItem__CTAs.compareItem__CTAs
  h1,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-2 text-section-heading;
  }
  p {
    @apply mb-2 text-default;
  }
  /* grid layout */
  .row {
    @apply flex flex-wrap w-full;
  }
  .custom-testimonial-heading {
    font-size: 30px; 
    font-family: 'SuzukiRegular'; 
    font-weight: 700; 
    line-height: 1; 
    margin-bottom: 0;
    letter-spacing: 0.02em; 
  }
  
  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }
  /* end of grid layout */

  /* arrow buttons */
  .button_dark_arrow,
  .button_light_arrow {
    @apply flex justify-between  w-full max-w-[300px] cursor-pointer items-end;
  }

  .button_dark_arrow a,
  .button_dark_arrow span,
  .button_light_arrow a,
  .button_light_arrow span {
    @apply flex-1 pb-2 text-left border-b-2 text-primary-blue font-suzukiBold border-b-gray;
  }
  .button_dark_arrow button,
  .button_dark_arrow--text,
  .button_light_arrow button {
    @apply flex items-center justify-center w-8 p-2 transition-all border-b-2 border-b-primary-blue;
    width: 38px;
    height: 38px;
  }
  /* .button_dark_arrow:hover button {
      @apply bg-primary-blue;
    } */
  .button_dark_arrow:hover .btn-drive {
    @apply bg-primary-blue;
  }
  .button_light_arrow a,
  .button_light_arrow span {
    @apply text-inverted;
  }
  .button_light_arrow button {
    @apply border-b-inverted;
  }
  .button_light_arrow:hover button {
    @apply bg-inverted;
  }
  .button_dark_arrow:hover button img {
    /* filter: invert(); */
    filter: grayscale(1) invert(1);
  }

  .button_dark_arrow:hover .btn-drive img {
    /* filter: invert(); */
    filter: grayscale(1) invert(1);
  }

  .button_light_arrow:hover button img {
    /* filter: invert(); */
    filter: grayscale(1) invert(1);
  }
  /* arrow buttons */
  /* header */
  .transparent-menu .blue-suzuki-logo {
    @apply hidden;
  }
  .transparent-bg {
    background-image: url("../public/assets/images/header/transparent-bg.png");
  }

  .background-nav {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(5px);
  }

  @media only screen and (max-width: 1920px) and (min-width: 767px) {
    .background-subNav {
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(5px);
    }
  }

  /* end of header */
  .bg-desktop-mapBtn {
    background: url("../public/assets/images/map-arrow.svg") 0 center no-repeat;
  }
  .bg-mobile-mapBtn {
    background: url("../public/assets/images/blue-arrow.svg") center center
      no-repeat;
  }
  .bg-white-arrow {
    background: url("../public/assets/images/white-arrow.svg") center center
      no-repeat;
  }


  /* .floating-bar {
      @apply fixed shadow-lg md:shadow-none lg:shadow-none bottom-[10px] right-[40%] translate-x-[130px] md:translate-x-0 md:translate-y-[-150px] md:top-1/2 md:right-6;
    } */

  /* .floating-bar {
    @apply fixed  bottom-[10px] right-[50%] translate-x-[130px] md:bottom-[10px] md:right-[50%] md:translate-x-[130px] lg:translate-x-0 lg:translate-y-[-150px] lg:top-1/2 lg:right-6;
  } */

  /* .floating-bar {
    @apply fixed  bottom-[10px] right-[50%] translate-x-[145px] md:bottom-[10px] md:right-[50%] md:translate-x-[130px] lg:translate-x-0 lg:translate-y-[-150px] lg:top-1/2 lg:right-6;
  } */

  .floating-bar {
    @apply fixed bottom-[10px] left-1/2 -translate-x-1/2 lg:right-[0px] lg:left-auto lg:top-1/2 lg:-translate-y-1/2;
  }

  /* .floating-bar ul {
      @apply flex flex-row md:flex-col;
    } */

  .floating-bar ul {
    @apply flex flex-row lg:flex-col;
  }

  .floating-bar ul li:nth-child(1) {
    @apply rounded-tl rounded-bl md:rounded-t md:rounded-bl-none
            /* border-top-left-radius: 5px;
            border-top-right-radius: 5px; */;
  }
  .floating-bar ul li:nth-child(1),
  .floating-bar ul li:nth-child(2),
  .floating-bar ul li:nth-child(3),
  .floating-bar ul li:nth-child(4),
  .floating-bar ul li:nth-child(5) {
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.1);
  }
  .floating-bar ul li:nth-child(4) {
    @apply rounded-tr rounded-br md:rounded-b md:rounded-br md:rounded-tr-none
            /* border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px; */;
  }
  /* .floating-bar ul li {
      @apply mr-0 border-0 shadow-lg bg-inverted px-[8px] md:px-0 lg:px-0;
    } */

  /* .floating-bar ul li {
    @apply mr-0 border-0 lg:shadow-lg px-0 bg-inverted inline-flex justify-center items-center;
  } */

  .floating-bar ul li {
    @apply mr-0 lg:shadow-lg px-0 bg-inverted inline-flex justify-center items-center;
  }
  /* .floating-bar ul li a {
      @apply w-[50px] h-[50px] md:h-[60px] text-center flex flex-col items-center justify-center p-1;
    } */

  /* .floating-bar ul li a {
    @apply w-[55px] lg:w-[50px] h-[50px] lg:h-[60px] px-[5px] text-center flex flex-col items-center justify-center;
  } */

  .floating-bar ul li a {
    @apply w-[60px] md:w-[70px] lg:w-[50px] h-[50px] lg:h-[60px] px-0 text-center flex flex-col items-center justify-center;
  }

  /* .floating-bar ul li a img {
    @apply transition-all;
  } */

  .floating-bar ul li a img {
    @apply lg:transition-all;
  }

  /* .floating-bar ul li a span {
      @apply text-verySmall block font-suzukiRegular mt-[5px] uppercase md:invisible transition-all md:scale-0;
    } */
  /* .floating-bar ul li a span {
      @apply text-verySmall md:text-small lg:text-small block font-suzukiRegular mt-[5px] uppercase transition-all md:invisible md:scale-0;
    } */


  /* .floating-bar ul li a span {
    @apply text-verySmall md:text-verySmall lg:text-small block font-suzukiRegular md:mt-0 mt-[8px] uppercase transition-all lg:invisible lg:scale-0;
  } */

  .floating-bar ul li a span {
    @apply text-verySmall md:text-verySmall lg:text-small block font-suzukiRegular md:mt-0 mt-[8px] uppercase lg:transition-all lg:invisible md:invisible invisible lg:scale-0 transition duration-500;
  }
  /* .floating-bar li.chat_icon {
      @apply ml-[12px] bg-transparent rounded-full md:ml-0 md:mt-5;
    } */
  /* .floating-bar li.chat_icon {
      @apply ml-[12px] bg-transparent rounded-full lg:ml-0 md:mt-5;
    } */
  .floating-bar li.chat_icon {
    @apply p-0 ml-5 bg-transparent rounded-full lg:ml-0 lg:mt-5;
  }
  .floating-bar li.chat_icon a {
    @apply flex items-center justify-center w-12 h-12 rounded-full lg:shadow-lg bg-inverted;
  }

  .social_links {
    @apply grid grid-cols-12 gap-x-[20px] gap-y-[30px] md:gap-10 mb-[41px];
  }

@media(min-width: 767px){
  .social_links {
    grid-template-columns: repeat(14, minmax(0, 1fr)) !important;
  }
}

  .slider {
   @apply flex flex-col items-center md:flex-row md:border-2 md:rounded-md md:border-gray;
  }
  .slider__image {
   @apply w-full md:flex md:w-auto md:rounded-tl-[5px] md:rounded-bl-[5px] md:overflow-hidden;
   margin: -2px;
  }
  .slider__details {
   @apply flex flex-col w-full  md:flex-col md:py-[20px] md:pr-[20px] md:pl-[57px] items-start md:items-start pt-[30px];
  }
  .slider__details--title {
   @apply font-suzukiBold text-heading  text-primary-blue uppercase w-full;
  }
  .slider__details--description {
   @apply uppercase font-suzukiRegular text-default mb-0 md:pt-0 md:pb-5  text-primary-blue w-full pt-[12px];
  }
  .slider__details--cta {
   @apply min-w-[50px] max-w-[50px] md:max-w-max md:min-w-[250px];
  }
  .slider__details--cta .button_dark_arrow {
   @apply justify-end md:justify-between;
  }
  .slider__details--cta .button_dark_arrow a,
  .slider__details--cta .button_dark_arrow span {
   @apply hidden md:block;
  }
  .brand__banner {
    @apply container-fluid;
  }
  
    .brand_slider .each-slide-effect {
     @apply lg:ml-5
  } 
  /* .brand_slider .indicators {
    @apply hidden md:flex !important;
  }  */
  .exterior360__longdesc {
    @apply mt-[50px] sm:mt-0;
  }
  .exterior360__longdesc h4 {
    @apply font-suzukiRegular md:font-suzukiBold text-section-heading md:text-sub-heading text-defaut-color max-w-[750px] mx-auto text-center mt-2;
    line-height: 30px;
  }
  .exterior360__plunginHTML,
  .interior360__plunginHTML {
    @apply relative;
  }
  .interior360__plunginHTML:after {
    @apply absolute top-[50%] bottom-0 left-0 right-0 z-0 w-full -rotate-180;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    content: "";
  }
  .exterior360__plunginHTML > img,
  .interior360__plunginHTML > img {
    @apply mx-auto md:w-full;
  }
  .exterior360__plunginHTML .exterior360__icon--container,
  .interior360__icon--container {
    @apply absolute top-[50%] left-[50%] max-w-[136px] translate-x-[-68px] translate-y-[-90px];
  }
  .exterior360__others {
    @apply flex justify-center items-center pt-[56px] pb-[99px];
  }
  .interior360__others {
    @apply flex justify-center items-center pt-[45px];
  }
  /* .exterior360__others--container {
      @apply md:max-w-[735px] mx-auto md:text-center flex flex-col md:items-center justify-center;
    } */
  .exterior360__others--container h5 {
    @apply font-suzukiBold text-heading mb-[20px];
  }
  .exterior360__others--container p {
    @apply font-suzukiRegular text-default mb-[20px];
  }
  .interior360__subsection {
    @apply grid grid-cols-1 gap-y-[25px] md:gap-y-0 md:grid-cols-2;
    /* grid-template-columns: 50% 50%; */
  }

  .exterior360__subsection {
    @apply grid grid-cols-1 gap-y-[25px] md:gap-y-0 md:grid-cols-2;
    /* grid-template-columns: 50% 50%; */
  }

  .interior360__subsection h5 {
    @apply font-suzukiBold text-heading;
    line-height: 37px;
  }
  .interior360__rightbox p {
    @apply font-suzukiRegular text-default mb-[20px];
  }
  .interior360 {
    @apply md:mb-[150px];
  }
  .interior360__html img {
    width: 100% !important;
    height: auto !important;
  }
  .brandFullCard {
    @apply flex flex-col md:flex-row items-end justify-between mb-[150px] md:mb-[150px];
  }
  .brandFullCard {
    display: flex !important;
  }
  .brandFullCard__details {
    @apply md:max-w-[320px] md:col_4 md:mx-auto md:px-5 2xl:max-w-max;
  }
  .brandFullCard__title {
    @apply font-suzukiBold text-sub-heading mt-[48px] md:mt-0 mb-[23px];
  }
  .brandFullCard__desc {
    @apply font-suzukiRegular text-default mb-8 mt-6;
  }
  .brandFullCard__image {
    @apply col_12 md:col_8;
  }
  .reverseOrder .brandFullCard__image {
    @apply md:order-1 md:text-right;
  }
  .fullwidth__card {
    @apply flex-col;
  }
  .fullwidth__card .brandFullCard__image {
    @apply col_12;
  }
  .fullwidth__card .brandFullCard__details {
    @apply container flex flex-col md:flex-row mx-auto pt-[50px];
  }
  .fullwidth__card .brandFullCard__details .brandFullCard__title {
    @apply col_12 md:col_6 text-sub-heading md:text-heading md:pr-[100px];
    line-height: 37px;
  }
  .fullwidth__card .brandFullCard__details .brandFullCard__desc_cta {
    @apply col_12 md:col_6;
  }
  .compare-section__heading {
    @apply font-suzukiBold text-heading md:text-heading2 max-w-[555px] text-primary-blue uppercase mb-[70px];
  }
  .compare-section__helperText {
    @apply text-default;
  }

  .compareItem {
    @apply flex flex-col  mt-[70px] mb-[100px];
  }

  .compareItem__selectbox {
    @apply w-full;
  }

  .compareBoard__blank-placeholder--fixed{
    @apply sticky h-[90px] w-full md:z-[1] z-[1] bg-[#ffffff] left-0 right-0
  }

  .compareItem__selectbox--fixed {
    max-width: calc(100vw / 4 - 50px);
    @apply fixed  z-[31];
  }
  @media(max-width: 767px){

    .compareItem__selectbox--fixed{
      @apply z-[1] md:z-[30]
    }

      .compareItem__selectbox--fixed {
          max-width: calc(100vw / 2 - 25px);
      }

      .compareItem_imageDetails{
        @apply !justify-start 
      }
  }

  @media only screen and (max-width: 768px) and (min-width: 768px){
    .compareItem__selectbox--fixed {
      max-width: calc(100vw / 4 - 20px);
      position: fixed;
      z-index: 31;
  }
}


  @media only screen and (max-width: 900px) and (min-width: 769px)  {
    .compareItem__selectbox--fixed {
        max-width: calc(100vw / 4 - 30px);
        position: fixed;
        z-index: 40;
    }
    .variant-btns{
      right: 48px !important
    }
  }

  @media only screen and (max-width: 1800px) and (min-width: 1600px)  {
    .compareItem__selectbox--fixed {
        max-width: calc(100vw / 4 - 70px);
    }
    .variant-btns{
      right: 100px !important
    }
  }

  @media only screen and (max-width: 1599px) and (min-width: 1500px)  {
    .compareItem__selectbox--fixed {
        max-width: calc(100vw / 4 - 100px);
    }
    .variant-btns{
      right: 114px !important
    }
  }

  @media only screen and (max-width: 2000px) and (min-width: 1801px)  {
    .compareItem__selectbox--fixed {
        max-width: calc(100vw / 4 - 100px);
    }
    .variant-btns{
      right: 125px !important
    }
  }




  .compareItem_imageDetails {
    @apply flex flex-col items-center justify-start;
  }
  .compareItem__name {
    @apply uppercase font-suzukiBold text-heading mb-[63px] hidden;
  }
  .compareItem_imageDetails img {
    max-height: 180px;
  }
 .compareItem__price {
    @apply font-suzukiBold text-section-heading md:text-heading flex gap-[2px] h-auto ;
}

  .compareItem__emiPrice {
    @apply font-suzukiRegular text-default uppercase mb-[60px];
  }
  .compareItem__keyFeatures,
  .compareItem__Performance {
    @apply flex flex-col justify-start align-top;
  }
  .compareItem__keyFeatures--heading,
  .compareItem__Performance--heading {
    @apply invisible uppercase font-suzukiBold text-section-heading;
  }
  .carCompare__container
    .compareItem:first-child
    .compareItem__keyFeatures
    .compareItem__keyFeatures--heading,
  .carCompare__container
    .compareItem:first-child
    .compareItem__Performance
    .compareItem__Performance--heading {
    @apply visible;
  }
  .carCompare__container {
    @apply md:mr-8;
  }
  /* .compareItem__keyFeatures--list,
    .compareItem__Performance--list {
      @apply mb-[50px] md:mb-[30px] border-b-[1px] border-b-gray mt-[20px] pb-[20px] md:pb-[30px] pt-[20px] md:pt-[30px] border-t-gray border-t-[1px] lg:h-[360px] md:h-[520px];
    } */
  .compareItem__keyFeatures--list,
  .compareItem__Performance--list {
    @apply mb-[50px] md:mb-[30px] border-b-[1px] border-b-gray mt-[20px] pb-[20px] md:pb-[30px] pt-[20px] md:pt-[30px] border-t-gray border-t-[1px];
  }
  .compareItem__CTAs {
    @apply flex flex-col items-center justify-center;
  }
  .compareItem__CTAs .button_dark_arrow:first-child {
    @apply mb-[15px];
  }
  .compareItem__CTAs .button_dark_arrow {
    @apply items-center border-2 border-primary-blue max-w-[280px];
  }
  .compareItem__CTAs .button_dark_arrow a,
  .compareItem__CTAs .button_dark_arrow span {
    @apply p-0 pl-[10px] border-0 text-small md:text-default !leading-[38px];
  }
  .compareItem__CTAs .button_dark_arrow:hover a,
  .compareItem__CTAs .button_dark_arrow:hover span {
    @apply bg-primary-blue text-inverted;
  }
  .compareItem__CTAs .button_dark_arrow button {
    @apply border-0;
  }


  .compareItem__CTAs .button_dark_arrows {
    @apply flex items-center max-w-[280px] border-b-2 border-b-[#B29DB2];
  }
  .compareItem__CTAs .button_dark_arrows a,
  .compareItem__CTAs .button_dark_arrows span {
    @apply p-0 border-0 text-[16px] md:text-[16px] !leading-[38px] text-primary-blue font-suzukiBold;
  }
  /* .compareItem__CTAs .button_dark_arrows:hover a,
  .compareItem__CTAs .button_dark_arrows:hover span {
    @apply bg-primary-blue text-inverted;
  } */
  .compareItem__CTAs .button_dark_arrows button {
    @apply border-0;
  }


  .compareItem__keyFeatures--list li {
    @apply font-suzukiRegular text-default mb-[15px];
    line-height: 17px;
  }
  .compareItem__keyFeatures--list li:last-child {
    @apply mb-0;
  }
  .compareItem__Performance--list li {
    @apply flex flex-col md:flex-row justify-between font-suzukiRegular text-default mb-[15px];
    line-height: 17px;
  }
  .compareItem__Performance--list li span:nth-child(2) {
    @apply md:ml-[10px] font-suzukiBold;
  }

  .compareItem__colorChoices {
    @apply flex flex-wrap items-center justify-center mt-[60px];
  }
  .compareItem__colorChoices li{
    @apply mr-2 mb-2
  }
  .compareItem__colorChoices label {
    @apply flex items-center justify-between cursor-pointer w-[20px] h-[20px] rounded-full shadow-[0px_0px_10px_rgba(0,0,0,0.13)];
  }
  /*.compareItem__colorChoices label span {
    @apply w-[18px] h-[18px] inline-block mr-2 rounded-full;
    box-shadow: 0px 0px 10px #d9d8d9;
  }*/
.compareItem__colorChoices label span {
        @apply w-[100%] h-full inline-block ;
        /* box-shadow: 0px 0px 10px #d9d8d9; */
    }  .compareItem__colorChoices input[type="radio"] + label span {
    transition: background 0.2s, transform 0.2s;
  }

  .compareItem_carVariant{
    @apply text-center
  }

  .compareItem_carVariant span{
    @apply block mt-1
  }
  /* .compareItem__colorChoices input[type="radio"] + label span:hover,
  .compareItem__colorChoices input[type="radio"] + label:hover span {
    transform: scale(1.2);
  } */

  .compareItem__colorChoices input[type="radio"]:checked + label span {
    @apply bg-primary-blue;
    /* transform: scale(1.2); */
    /* box-shadow: 0px 0px 0px 6px white inset; */
  }

  .compareItem_carVariant{
    @apply text-default text-defaut-color font-suzukiRegular
  }

  .compareItem_carVariant span{
    @apply block text-tab-color
  }

  /* .compareItem__colorChoices input[type="radio"]:checked + label {
    @apply bg-[url('/checkIcon.svg')] bg-no-repeat  bg-[length:50px] w-[28px] h-[28px] bg-center p-[3px] border-[2px] border-primary-blue
  } */
  .compareItem__colorChoices input[type="radio"]:checked + label {
    @apply bg-[length:50px] w-[28px] h-[28px] bg-center p-[3px] border-[2px] border-primary-blue
  }
  .car_selecBox {
    @apply block w-52;
  }
  .car_selecBox--listbox {
    @apply relative flex py-2 pl-3 pr-10 mx-auto text-left uppercase rounded-lg cursor-default md:text-defaut-color md:text-heading bg-inverted focus:outline-none focus-visible:border-light-gray focus-visible:ring-2 focus-visible:ring-light-gray focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-light-gray font-suzukiBold text-section-heading text-primary-blue;
  }
  .car_selecBox--listboxOptions {
    @apply absolute top-[80px] lg:top-[50px] w-full py-1 mt-1 overflow-auto text-default z-[1] bg-inverted rounded-md shadow-lg max-h-60 ring-1 ring-gray ring-opacity-5 focus:outline-none;
  }
  .brand__banner:after {
    @apply absolute top-0 bottom-[7px] left-0 right-0 w-full -rotate-180 after:content-[""];
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .brand__banner--position {
    /* @apply absolute flex flex-col z-[1] top-1/2 2xl:top-[65%]; */
    @apply absolute flex flex-col z-[0] bottom-[6%] md:bottom-[5%] w-[85%] md:w-auto lg:w-auto;
  }

  .brand__banner--model_name {
    /* @apply uppercase font-suzukiBold text-bannerHeading text-inverted mb-[25%] md:mb-[8%] lg:mb-[15%] xl:mb-[20%]; */
    @apply uppercase font-suzukiBold text-heading42 md:text-bannerHeading text-inverted mb-[5px] md:mb-0 lg:mb-[0] xl:mb-[0px];
    text-shadow: 0px 5px 13px rgb(0 0 0 / 45%);
  }

  .brand__banner--model_info {
    @apply flex flex-row  justify-between align-top gap-[100px] md:text-center md:text-left lg:text-left;
  }
  .brand__banner--model_info p {
    @apply mr-0 md:mr-5 lg:mr-5 text-inverted font-suzukiBold text-section-heading md:text-heading;
    text-shadow: 0px 5px 13px rgb(0 0 0 / 45%);
  }

  @media only screen and (max-width: 440px) {
    .brand__banner--model_info {@apply align-top gap-[50px]}
    .brand__banner--position {@apply  bottom-[1%]}

  }
  .brand__banner--model_info p small {
    @apply block font-suzukiRegular text-small md:text-default;
  }
  .mobile_banner {
    @apply col_12;
  }
  .mobile_banner img {
    @apply col_12 block !important;
  }
  /* .mobile_imgf{
      background-image: url('../public/assets/images/cars/banner-img1.jpg');
    }
    .imgf {
      background-image: url('../public/assets/images/cars/mobile-banner.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
      @apply sm:mobile_imgf;
    } */
  .car_details__select {
    @apply uppercase bg-inverted text-default shadow-sm font-suzukiBold max-w-[163px] rounded-md float-right m-2 min-w-[163px];
  }

  /* back to top */
  .backToTop__btn {
    @apply fixed h-[40px] w-[40px] bottom-[20px] rounded-md flex items-center justify-center bg-[#001151] right-[30px] shadow-md hidden md:block;
  }
  .backToTop__btn img {
    @apply transform -rotate-90;
  }
  .footer__menu--submenu li a {
    @apply h-[60px] relative block flex items-center;
  }
  .footer__menu--submenu li a:after {
    content: "";
    background: url("../public/assets/images/blue-arrow.svg");
    background-size: 13px 10px;
    background-repeat: no-repeat;
    position: absolute;
    width: 13px;
    height: 10px;
    right: 0;
    top: 50%;
  }
  .brand-logo-container {
    @apply border-b-[1px] border-light-gray flex items-center h-[60px];
  }
  /* home page */
  .banner_slide {
    @apply flex flex-col md:flex-row;
  }
  .banner_slide--left .banner_card {
    @apply text-inverted;
  }
  .banner_slide--right {
    @apply flex flex-col;
  }
  .banner_card {
    @apply relative;
  }
  .banner_card .commonHomeCard__image {
    @apply flex flex-col flex-wrap;
  }
  .banner_card .commonHomeCard__details h3 {
    @apply mb-3 uppercase font-suzukiBold;
  }
  .banner_card .commonHomeCard__details h1 {
    @apply mb-3 uppercase font-suzukiBold;
  }
  .banner_card .commonHomeCard__details p {
    @apply mb-4 uppercase font-suzukiRegular;
  }
  /* .banner_card .commonHomeCard__details {
    @apply absolute bottom-[60px] left-[7%] md:left-[8.5%] md:max-w-sm;
  } */

  .banner_card .commonHomeCard__details {
    @apply absolute left-[7%] md:left-[8.5%] md:max-w-sm;
  }

  /* .banner_slide--left .banner_card .commonHomeCard__details h3 {
    @apply text-inverted;
  } */
  .banner_slide--right .banner_card:nth-child(1) .commonHomeCard__details {
    @apply text-inverted;
  }
  .banner_slide--right .banner_card:nth-child(2) .commonHomeCard__details {
    @apply text-inverted;
  }
  .banner_slide--right .banner_card:nth-child(1) .commonHomeCard__details p {
    @apply mb-0;
  }
  .banner_slide--right .banner_card .commonHomeCard__details {
    @apply left-[7%] md:left-[20px] lg:left-[43px];
  }
  .fullwidth_homeCard .commonHomeCard__image {
    @apply items-center justify-center w-full;
  }
  .fullwidth_homeCard {
    @apply mb-[50px] md:mb-[100px] container-fluid;
  }
  .fullwidth_homeCard .commonHomeCard__details {
    @apply container mx-auto  pt-[50px];
  }
  .fullwidth_homeCard .commonHomeCard__details--info {
    @apply md:max-w-[650px];
  }
  .fullwidth_homeCard .commonHomeCard__details .commonHomeCard__details--title {
    @apply mb-4 font-suzukiBold text-heading;
  }
  .fullwidth_homeCard .commonHomeCard__details .commonHomeCard__details--desc {
    @apply mb-5 font-suzukiRegular text-default;
  }
  .homeCms__container {
    @apply grid grid-cols-1 mt-[50px] md:mt-0 md:grid-cols-3;
  }
  .homeCms__cardLinks {
    @apply mb-[50px] md:mb-[103px];
  }
  /* .homeCms__cardLinks .commonHomeCard__image {
      @apply hidden
    } */
  .homeCms__cardLinks .commonHomeCard__details .commonHomeCard__details--title {
    @apply mb-4 font-suzukiBold text-heading text-defaut-color text-[24px];
  }
  .homeCms__cardLinks .commonHomeCard__details .commonHomeCard__details--desc {
    @apply font-suzukiRegular text-default mb-[20px] md:min-h-fit;
  }
  .homeCms__cardLinks .commonHomeCard__details .button_dark_arrow {
    @apply max-w-none text-default md:max-w-[400px];
  }
  .topics__container {
    @apply container mx-auto mb-[80px] md:mb-[150px];
  }
  .topics__container--heading {
    @apply mb-10 uppercase font-suzukiBold text-primary-blue text-heading;
  }
  .topicCard__details--title {
    @apply text-primary-blue min-h-[45px];
  }
  .topicCard {
    @apply mr-10;
  }
  .topicCard .topicCard__image {
    @apply mb-5;
  }
  .topicCard .topicCard__image img {
    @apply overflow-hidden rounded-md;
  }
  .topicCard .topicCard__details--date {
    @apply mb-2 text-default font-suzukiRegular;
  }
  .topicCard .topicCard__details--desc {
    @apply text-default font-suzukiRegular mb-[5px];
  }
  .topicCard .button_dark_arrow {
    @apply text-default;
  }
  .perfectSuzuki__container {
    @apply pt-[100px] mb-[100px];
  }
  .perfectSuzuki__container--sectionHeading {
    @apply font-suzukiBold text-heading text-primary-blue mb-[48px] uppercase;
  }
  .perfectSuzuki__container--tabs {
    @apply md:container md:mx-auto;
  }
  /* .homeSuzukiCard--container {
      @apply grid grid-cols-2 md:grid-cols-4 gap-x-[48px] gap-y-12;
    } */
  .homeSuzukiCard--container {
    @apply grid grid-cols-2 md:grid-cols-4 gap-x-[48px] md:gap-x-[16px] lg:gap-x-[48px] gap-y-12;
  }
  .homeSuzukiCard__image {
    @apply mb-3 md:mb-5;
  }
  .homeSuzukiCard__details {
    @apply flex flex-row items-end justify-between;
  }
  .homeSuzukiCard__details .button_dark_arrow {
    @apply w-auto max-w-none;
  }
  .homeSuzukiCard__details .button_dark_arrow a,
  .homeSuzukiCard__details .button_dark_arrow span {
    @apply hidden min-w-[38px];
  }
  .homeSuzukiCard__details--title {
    @apply font-suzukiBold text-section-heading md:text-sub-heading uppercase mb-[10px];
  }
  .homeSuzukiCard__details--price {
    @apply mb-[5px] font-suzukiBold text-small2 md:text-section-heading;
  }
  .homeSuzukiCard__details--fuelType {
    @apply mb-[5px] text-lightBlue md:text-defaut-color uppercase font-suzukiRegular text-small2 md:text-default;
  }
  .homeSuzukiCard__details--seater {
    @apply text-small2 md:text-default font-suzukiRegular;
  }

  .homeSuzukiCard__priceInfo {
    @apply flex flex-wrap items-center ml-[2px] w-[100%];
  }

  .homeSuzukiCard__priceInfo_title{
    @apply  pl-[5px] mb-0 text-[12px] underline font-suzukiRegular ;
  }

  .text-mobileMenu {
    @apply capitalize;
  }
  .bk-menu {
    @apply md:text-inverted;
  }
  .bcard_orderSecond .button_dark_arrow {
    @apply max-w-[175px] absolute left-0 md:left-auto md:right-[-65px] bottom-[-37px] md:bottom-[-33px] transition-all md:scale-0;
  }
  .bcard_orderSecond .button_dark_arrow a,
  .bcard_orderSecond .button_dark_arrow span {
    @apply bg-inverted border-0 rounded-md pt-[8px] pb-[6px] px-[25px] text-center h-[31px] text-small text-defaut-color;
  }
  .bcard_orderSecond .button_dark_arrow button {
    @apply hidden;
  }
  .bcard_orderSecond:hover .button_dark_arrow {
    @apply scale-100;
  }
  .genericPage__container {
    @apply bg-inverted;
  }
  .genericPage__container--title {
    @apply mb-4 uppercase text-heading text-primary-blue;
  }
  .genericPage__container--banner {
    @apply flex mb-[65px];
  }
  .genericPage__container--htmlcontent {
    @apply mb-[90px] mt-[60px] font-['SuzukiRegular'] text-[14px] leading-[21px] overflow-x-auto;
  }
  .genericPage__container--htmlcontent td {
    @apply p-[20px];
  }
  .findDealer__heading {
    @apply font-suzukiBold mt-[90px] md:mt-[150px] lg:mt-[150px] mb-[50px] text-[30px] leading-[31px] md:text-[60px] md:leading-[74px]  uppercase text-primary-blue;
  }
  /* searchbox header */
  .searchbox-container {
    @apply absolute right-0 flex w-0 bg-inverted border-b-[1px] border-b-defaut-color justify-between;
    transition: width 0.75s cubic-bezier(0, 0.795, 0, 1);
  }
  .searchbox-container button {
    @apply hidden w-0 bg-inverted;
  }
  .searchInput {
    @apply w-0 focus:outline-none;
  }
  .search-dropdown {
    display: none;
  }
  .active-search.searchbox-container .search-dropdown {
    display: block;
  }
  .active-search {
    @apply w-[320px] z-[1];
  }
  /* .active-search input[type="text"] {
      @apply w-48 px-2 py-1 text-default;
    } */
  .active-search input[type="text"] {
    @apply w-[90%] px-2 py-1 text-default;
  }
  .active-search button {
    @apply visible block w-auto;
  }
  /* Dealer Locator */
  /* .locatorContainer {
      @apply h-[771px] bg-primary-blue mt-5 flex;
    } */
  .locatorContainer {
    @apply h-[771px] bg-primary-blue flex;
  }
  .locatorContainer .locatorContainer--listing {
    @apply w-full md:w-[430px] md:h-[731px] md:mr-[3px] md:mb-5 md:min-w-[430px] pl-5 md:pl-[53px] overflow-y-auto pt-[75px] md:mt-5 md:pt-0 pr-[15px] !bg-light-gray md:!bg-primary-blue md:left-[-100%] z-[1] transition-all;
  }
  /* ------------------------ */
  #listing-scroller::-webkit-scrollbar-track {
    @apply bg-gray;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }
  #listing-scroller::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
  }
  #listing-scroller::-webkit-scrollbar-thumb {
    background-color: #fff;
    border: 2px solid #fff;
  }

  /* find dealer  */
  .findDealer__options--optionList input[type="radio"]:checked,
  .findDealer__options--optionList input[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  .findDealer__options--optionList label {
    @apply mr-[15px] py-[7px] px-[15px] text-small text-primary-blue border border-primary-blue rounded-2xl font-suzukiBold uppercase cursor-pointer;
  }
  .findDealer__options--optionList input[type="radio"]:checked + label {
    background: #110051;
    color: #fff;
  }
  .findDealer__options {
    @apply flex flex-col justify-between md:flex-row md:align-middle;
  }

  /* .findDealer__options--optionList ul {
      @apply flex flex-row flex-wrap;
    } */

  /* .findDealer__options--optionList ul {
      @apply flex flex-row w-full overflow-x-auto overflow-y-hidden whitespace-nowrap pb-[20px] p-[10px];
    } */

.marginclass p{
  margin-bottom: 0px !important;
  height: 18px;
}
.starstyle{
  justify-content: flex-start !important;
  align-items: baseline !important
}
.customPlaceholder {
  position: absolute;
  top: 60%;
  left: 10px;
  transform: translateY(-50%);
  color: #999 !important;  /* Enforce this color */
  font-size: 14px;
  pointer-events: none;
}

/* Ensure that any inline styles or inherited styles don't override this */
.customPlaceholder * {
  color: inherit !important; /* Force inherited color for all child elements */
}

  .findDealer__options--optionList ul {
    @apply flex flex-row w-full overflow-x-auto overflow-y-hidden whitespace-nowrap p-[10px];
  }

  .findDealer__options--optionList ul li {
    @apply mb-4;
  }
  .locatorlistMapToggler .findDealer__options--optionList ul li {
    @apply mb-0;
  }
  .findDealer__options--optionList {
    @apply order-1;
  }
  .findDealer__options--search {
    @apply mb-5 md:mb-0 md:order-1 md:mr-7;
  }

  .findDealer__options--search .input-group {
    @apply flex min-w-[280px];
  }
  .findDealer__options--search input {
    @apply w-full py-2 pr-2 transition-all border-b-2 border-b-gray focus:outline-none text-default text-tab-color font-suzukiBold;
  }
  .findDealer__options--search button {
    @apply p-2 transition-all border-b-2 border-b-primary-blue focus:outline-none min-w-[38px] items-center justify-center flex;
  }
  .locatorContainer--map {
    width: 100%;
  }
  .locationCard {
    @apply bg-inverted rounded-[5px] p-[15px] mb-[15px] relative;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  }
  .locationCard--dealerName {
    @apply uppercase font-suzukiBold text-primary-blue text-section-heading mb-[7px] pr-8 break-words;
  }
  .locationCard--callDetail {
    @apply pl-5 mb-2 text-lightBlue text-default;
    background: url("../public/assets/images/ph-icon.svg") 0 center no-repeat;
  }
  .locationCard--email {
    @apply pl-5 mb-2 text-defaut-color text-default;
    background: url("../public/assets/images/email-icon.svg") 0 center no-repeat;
  }
  .locationCard--websiteUrl{
    @apply pl-5 mb-2 text-lightBlue text-default;
    background: url("../public/assets/images/tabler_world-up.svg") 0 center no-repeat;
  }
  .locationCard--openCloseDetails {
    @apply pl-5 text-default;
    background: url("../public/assets/images/open-close.svg") 0 center no-repeat;
  }
  .locationCard--distance {
    @apply mb-2;
    background: url("../public/assets/images/dealer-locator/locator-distance.svg")
      0 center no-repeat;
    background-size: 15px;
  }
  .locationCard--Time {
    background: url("../public/assets/images/Vector.svg")
      0 center no-repeat;
    background-size: 13px;
  }
  .navigationLink {
    @apply w-[24px] h-[24px] absolute top-0 right-0 block;
    background: url("../public/assets/images/dealer-locator/navigation-icon.svg")
      0 center no-repeat;
  }
  .locationCard--openCloseDetails .openClose-status {
    @apply mr-2 uppercase text-lightGreen text-default;
  }
  .locationCard--ratingBox {
    @apply font-suzukiRegular text-verySmall flex max-w-[66px] text-gray justify-between mb-[12px];
  }
  .locationCard--ratingBox .highlight {
    @apply text-primary-blue;
  }
  .locationCard--modalToggleBtn {
    @apply w-[38px] h-[38px] md:rotate-[-90deg] lg:rotate-[-90deg] absolute right-[15px] bottom-[15px] border-b-2 md:border-b-0 border-b-primary-blue md:w-[24px] md:h-[24px] md:absolute md:top-[15px] md:right-[15px] md:bg-cover md:bg-desktop-mapBtn bg-mobile-mapBtn;
  }

  .locatorContainer--map {
    @apply bg-inverted;
  }
  .locatorContainer--map > img {
    @apply w-full;
  }
  /* .locatorModal {
      @apply fixed md:absolute md:!left-[435px] md:min-h-[762px] md:!top-[377px] md:max-w-[400px] md:!right-auto md:!bottom-auto md:h-[100%] md:min-w-[400px] z-10 md:z-0;
    } */
    @layer utilities {
      .bg-black {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
    .customModal {
      @apply shadow-lg md:max-w-[925px] max-w-[350px] rounded-[5px] bg-black;
    }
  .locatorModal {
    @apply fixed md:absolute md:!left-[427px] md:min-h-[762px] md:!top-[377px] md:max-w-[400px] md:!right-auto md:!bottom-auto md:h-[100%] md:min-w-[400px] z-10 md:z-[1];
  }
  .locatorModal > div {
    @apply md:items-start;
  }
  .modal_data .locationCard {
    @apply p-0 m-0 !shadow-none;
  }
  .locatorModal .modal-close {
    @apply absolute border border-transparent right-3 top-3 focus:outline-none;
  }
  .locationCard--dealerShowroomImages {
    @apply mt-5 mb-4;
  }
  .locationCard--dealerShowroomImages img {
    @apply w-full;
  }
  .locationCard--addressDetail {
    @apply relative pl-5 mb-2 text-default pr-9;
    background: url("../public/assets/images/locator-icon.svg") 0 4px no-repeat;
    background-size: 14px;
  }
  .locationCard--cpanel-timings ul {
    @apply pl-5 mt-2;
  }
  .locationCard--cpanel-timings p {
    @apply mb-[5px] text-small2;
  }
  .locationCard--cpanel-timings p span {
    @apply min-w-[100px] inline-block text-tab-color;
  }
  .singleDealer--card {
    @apply hidden absolute max-w-[330px] min-w-[330px] bottom-[25%] left-[50%] ml-[-165px];
  }
  .activesingleDealer--card {
    @apply absolute max-w-[330px] min-w-[330px] bottom-[25%] left-[50%] ml-[-165px];
  }
  .addLeftMargin {
    @apply ml-0 md:ml-[430px];
  }
  .cl-data {
    @apply hidden transition-all mb-[15px] pb-[15px] border-b-[1px] border-b-gray;
  }
  .cl-data.active {
    @apply flex;
  }
  .cl-title {
    @apply cursor-pointer relative after:content-[""] after:absolute after:w-[10px] after:h-[10px] after:mt-[-5px] after:top-[50%] after:right-0 after:transition-all;
  }
  .cl-title:after {
    background: url("../public/assets/images/cl-dropicon.svg") 0 center
      no-repeat;
  }
  .active.cl-title:after {
    @apply rotate-180;
  }
  .locationCard--services {
    @apply mt-4 uppercase font-suzukiBold text-section-heading text-primary-blue;
  }
  .locationCard--servicesDetails ul {
    @apply flex flex-wrap w-full mt-4 ml-5;
  }
  .locationCard--servicesDetails ul li {
    @apply pl-5 mb-2 basis-1/2 text-small2 text-primary-blue font-suzukiRegular;
    background: url("../public/assets/images/locator-service-icon.svg") 0 center
      no-repeat;
  }
  .locationCard__CTAs {
    @apply flex flex-col items-center justify-center mt-4 gap-5;
  }
  .locationCard__CTAs .button_dark_arrow:last-child {
    @apply mb-0;
  }
  .locationCard__CTAs .button_dark_arrow {
    @apply items-center border-2 border-primary-blue max-w-[280px] mb-4;
  }
  .locationCard__CTAs .button_dark_arrow a,
  .locationCard__CTAs .button_dark_arrow span {
    @apply p-0 pl-[10px] border-0 text-default;
  }
  .locationCard__CTAs .button_dark_arrow button {
    @apply border-0;
  }

  /* .locatorlistMapToggler {
    @apply absolute shadow-md bg-inverted z-[1] m-5 right-[36px];
  } */

  .locatorlistMapToggler {
    @apply absolute shadow-md bg-inverted z-[1] m-5 max-w-[250px] md:max-w-none right-auto md:right-[36px];
  }

  @media only screen and (max-width: 385px) {
    .locatorlistMapToggler {
      @apply right-[-10px]
    }
  }

  @media only screen and (max-width: 440px) {
    .locatorlistMapToggler {
      @apply right-[-10px]
    }
  }

  .locatorlistMapToggler .findDealer__options--optionList label {
    @apply rounded-[2px] mr-[-1px] min-h-[34px] flex justify-center items-center border-inverted;
  }
  .locationCard__CTAs p .button_dark_arrow {
    margin: 0 auto;
  }
  .locatorlistMapToggler
    .findDealer__options--optionList
    input[type="radio"]:checked
    + label {
    @apply border-primary-blue;
  }
  .mobileToggleBtn .button_dark_arrow {
    @apply max-w-[38px] absolute bottom-5 right-5;
  }
  .mobileToggleBtn .button_dark_arrow span {
    @apply hidden;
  }
  .cookiesContainer {
    @apply fixed top-0 bottom-0 left-0 right-0 z-10 bg-primary-blue bg-opacity-20;
  }
  .cookiesContainer--info {
    @apply absolute bottom-0 p-5 container-fluid bg-primary-blue text-inverted;
  }
  .cookiesContainer--info-details {
    @apply max-w-[1000px] mx-auto text-center;
  }
  .cookiesContainer--info-details p {
    @apply mb-5 text-inverted text-default;
  }
  .desktopFooter-heading {
    @apply text-section-heading mb-[15px];
  }
  /* find dealer  */
  .brand-page__topic-slider {
    @apply md:mx-14 md:my-14;
  }
  /* cms pages */
  .genericPage__container--htmlcontent {
    @apply font-['SuzukiRegular'] text-[14px] leading-[21px];
  }

  .genericPage__container--htmlcontent table td {
    @apply border border-[#dadada];
  }

  .genericPage__container--htmlcontent td {
    padding: 20px;
  }

  .genericPage__container--htmlcontent table td {
    @apply border border-[#dadada];
  }
  .genericPage__container--htmlcontent table th {
    @apply border border-[#dadada];
  }
  /* .car_selecBox--listboxOptions {
      @apply min-w-[300px] md:min-w-full
    } */

  .car_selecBox--listboxOptions {
    @apply min-w-full md:min-w-full;
  }
  /* cms pages end */

  /* cookie consent */
  .CookieConsent {
    /* @apply flex-col !items-center;
     */
     @apply !items-center lg:px-[50px] flex-col;
  }
  .content-wrapper {
    /* @apply !flex-none container text-center; */
    @apply !flex-none container text-center w-[80%];
  }
  .content-wrapper p {
    @apply mb-0 font-suzukiRegular text-default;
  }
  .cookie-button-wrapper {
    @apply mb-5;
  }
  .cookie-button-wrapper #rcc-confirm-button {
    @apply flex relative items-center pr-[50px] pl-[10px] h-[38px] after:content-[""] after:w-[38px] after:h-[38px] after:absolute after:right-0 after:bg-white-arrow text-left border-2 text-inverted font-suzukiRegular text-default border-inverted min-w-[200px] hover:after:bg-mobile-mapBtn hover:after:bg-inverted;
  }
  /* plugins custom changes */
  /* tabs styling */
  .react-tabs {
    @apply bg-transparent;
  }
  /* .react-tabs .react-tabs__tab-list {
      @apply border-b-2 border-b-gray mb-[26px] pl-[5%] md:pl-0 whitespace-nowrap overflow-x-scroll md:overflow-x-auto overflow-y-hidden md:whitespace-normal;
    } */
      /* .react-tabs-children{
        @apply bg-blue-500;
      } */

  .react-tabs .react-tabs__tab-list {
    @apply border-b-2 border-b-gray mb-[36px] pl-[5%] md:pl-0 whitespace-nowrap overflow-x-scroll md:overflow-x-auto overflow-y-hidden md:whitespace-nowrap;
  }

.react-tabs-children ul.react-tabs__tab-list{
  @apply border-b-0 
}

  .react-tabs-children ul.react-tabs__tab-list li.react-tabs__tab{
    @apply border-2 border-primary-blue mr-[20px] rounded-[90px] 
  } 

  .react-tabs-children ul.react-tabs__tab-list li.react-tabs__tab.react-tabs__tab--selected{
    @apply bg-primary-blue text-inverted
  } 


  .react-tabs .react-tabs__tab {
    @apply font-suzukiBold uppercase text-center text-default min-w-[125px] text-tab-color px-[20px] py-[10px] relative inline-block border-b-2 bottom-[0px] border-transparent cursor-pointer focus:outline-none;
  }
  .react-tabs .react-tabs__tab--selected {
    @apply border-b-4 text-primary-blue border-b-primary-blue;
  }
  .react-tabs .hybrid-tabs .react-tabs__tab--selected {
    @apply bg-primary-blue text-[#fff] rounded-[30px];
  }
  .react-tabs .react-tabs__tab:focus::after {
    width: 0 !important;
  }
  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
  html {
    @apply md:!overflow-auto;
  }
  .overFlowHiiden {
    @apply !overflow-hidden;
  }

  /*end of tabs styling */

  .brandPage-cards .slick-arrow-container button.slick-arrow.brand-slicks-prev {
    /* background-image: url("/leftArrowBrand.svg");
        background-repeat: no-repeat;
        background-size: 20px; */
    display: block !important;
    top: auto;
    bottom: -80px;
    right: auto;
    left: 0;
    background-color: transparent;
    z-index: 4;
    width: 40px;
    height: 40px;
    border: solid 1px #001151;
    border-radius: 100%;
    margin: 0;
  }

  .kids-print-slider-wrapper .slick-slider .slick-list .slick-track {
    @apply ml-0 mr-0;
  }

  .brandPage-cards
    .slick-arrow-container
    button.slick-arrow.brand-slicks-prev::after {
    content: url("/leftArrowBrand.svg");
    position: absolute;
    background-repeat: no-repeat;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .brandPage-cards .slick-arrow-container button.slick-arrow.brand-slicks-next {
    /* background-image: url("/RightArrowBrand.svg");
        background-repeat: no-repeat;
        background-size: 20px; */
    display: block !important;
    top: auto;
    bottom: -80px;
    right: auto;
    left: 60px;
    background-color: transparent;
    z-index: 4;
    width: 40px;
    height: 40px;
    border: solid 1px #001151;
    border-radius: 100%;
    margin: 0;
  }

  .brandPage-cards
    .slick-arrow-container
    button.slick-arrow.brand-slicks-next::after {
    content: url("/RightArrowBrand.svg");
    position: absolute;
    background-repeat: no-repeat;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .brandPage-cards .slick-slider button.slick-arrow {
    display: none !important;
  }

  /*
      .brandPage-cards .slick-slider button.slick-arrow.slick-prev {
        bottom: 19%!important;
        z-index: 1;
        top: auto;
        background-color: transparent;
        border: solid 2px #001151;
        border-radius: 100%;
        left: 72%!important;
        right: auto!important;
      }

      .brandPage-cards .slick-slider button.slick-arrow.slick-next {
        bottom: 19%!important;
        z-index: 1;
        top: auto;
        background-color: transparent;
        border: solid 2px #001151;
        border-radius: 100%;
        left: 77%;
        right: auto!important;
      }

  swiper-button-prev

      .brandPage-cards button.slick-arrow.slick-next:after{
        content: url("/RightArrowBrand.svg");
      }

      @media screen and (max-width: 767px) {
        .brandPage-cards .slick-slider button.slick-arrow.slick-next{
          bottom: 10%!important;
          left: 120px!important;
          background-color: transparent!important;
        }

        .brandPage-cards .slick-slider button.slick-arrow.slick-prev{
          bottom: 10%!important;
          left: 50px!important;
          background-color: transparent!important;
        }
      }


      @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        .brandPage-cards .slick-slider button.slick-arrow.slick-next{
          bottom: 29%!important;
          left: auto!important;
          right: 16%!important;
          background-color: transparent!important;
        }

        .brandPage-cards .slick-slider button.slick-arrow.slick-prev{
          bottom: 29%!important;
          left: auto!important;
          right: 25%!important;
          background-color: transparent!important;
        }
      }
       */

  .react-slideshow-container + ul.indicators li {
    width: 31px !important;
  }
  .react-slideshow-container + ul.indicators .each-slideshow-indicator:before {
    width: 26px !important;
    height: 2px !important;
  }
  .border-transparent {
    border-color: transparent;
  }

  /* FINANCE PAGE CSS START */

  h3.banner-heading {
    font-weight: 400;
    letter-spacing: 0.02em;
  }

  h1.banner-heading {
    font-weight: 400;
    letter-spacing: 0.02em;
  }

  /* FINANCE PAGE CSS END */

  .btnCart {
    padding: 12px 28px;
    border: solid 1px;
    border-radius: 90px;
    display: inline-block;
    font-size: 14px;
  }

  .parts-classes .react-tabs .react-tabs__tab-list {
    border-bottom-width: 0 !important;
    padding-left: 0 !important;
  }

  .testimonial-sliderClass button.slick-arrow.slick-next{
    @apply z-[1] md:z-[1];
    display: block !important;
    top: auto;
    bottom: 0;
    right: 0;
    left: auto;
    background-color: transparent !important;
    /* z-index: 4; */
    width: 40px;
    height: 40px;
    border: solid 1px #001151;
    border-radius: 100%;
    margin: 0; 
  }

  .testimonial-sliderClass button.slick-arrow.slick-prev{
    @apply z-[1] md:z-[1];
    display: block !important;
    top: auto;
    bottom: 0;
    right: auto;
    left: 0;
    background-color: transparent !important;
    /* z-index: 4; */
    width: 40px;
    height: 40px;
    border: solid 1px #001151;
    border-radius: 100%;
    margin: 0; 
  }

  .testimonial-sliderClass button.slick-arrow.slick-prev:after{
    content: url("/assets/images/testimonial/sliderBlueArrow.svg");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    
  }

  .testimonial-sliderClass button.slick-arrow.slick-next:after {
    content: url("/assets/images/testimonial/sliderBlueArrowRight.svg");
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  /* .customBtn button.slick-arrow {
      display: none!important;
    }

    .button-wrapper.newBtns {
      display: none;
    } */

  .new-mobile-sliders {
    @apply border-b-2 border-b-gray mb-[26px] md:pl-0 whitespace-nowrap overflow-x-scroll md:overflow-x-auto overflow-y-hidden md:whitespace-normal;
  }
  /* page and accessories page end */
}

/*start of forms*/

/*forms__enquiry*/
.enquiry__container {
  @apply w-full max-w-[300px] bg-[#d9d9d9] mx-auto rounded-lg p-4;
}

.enquiry__container .title,
.logo {
  @apply font-bold text-[20px] leading-[24px] text-[#001151];
}

.enquiry__container input[type="text"],
.enquiry__container input[type="email"],
.enquiry__container input[type="tel"],
.enquiry__container select {
  @apply rounded-[5px] block border-[1px] border-[#001151] outline-none px-1 text-[12px] w-[264px] h-[32px];
}

.enquiry__container label {
  @apply block mt-[12px] text-[12px] leading-[15px] text-[#001151];
}

.req-text {
  @apply text-[10px];
}

.enquiry__container .submit {
  @apply text-[#fff] border-[1px] border-[#001151] w-[264px] h-[32px] rounded-[90px] bg-[#001151] text-[16px];
}

/*forms__finance*/

.finance__container {
  @apply w-[300px] mx-auto flex flex-col sm:w-full;
}

.finance__container .finance__subtitle {
  @apply bg-[#EFFAFC] p-2 font-bold text-[#001151] text-[16px] leading-[19px];
}

.finance__container .finance__container1 {
  @apply bg-[#d9d9d9] flex flex-col p-4;
}

.finance__container .title {
  @apply text-center p-2 text-[24px] leading-[29px] text-[#001151] font-[700];
}

div.finance__container input[type="text"],
div.finance__container input[type="email"],
div.finance__container input[type="tel"],
div.finance__container select {
  @apply shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-none block border-none outline-none px-1 text-[12px] w-[264px] h-[32px];
}

.shadowCLass{
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

div.finance__container label {
  @apply block mt-[12px] mb-[6px] text-[12px] leading-[15px] font-[700]  text-[#001151] tracking-[0.1em];
}

div.finance__container .submit {
  @apply text-[#fff] border-[1px] border-[#001151] w-[105px] h-[32px] rounded-[90px] bg-[#001151];
}

/*Brochure__form*/
.brochure__container {
  @apply w-[300px] mx-auto flex flex-col sm:w-full bg-[#d9d9d9] p-4;
}
.brochure__container .title,
.logo {
  @apply font-bold text-[20px] leading-[24px] text-[#001151];
}

div.brochure__container input[type="text"],
div.brochure__container input[type="email"],
div.brochure__container input[type="tel"],
div.brochure__container select {
  @apply shadow-[inset_0px_4px_4px_rgba(0,0,0,0.25)] rounded-none block outline-none px-1 text-[12px] w-[264px] sm:w-[200px] h-[32px];
}

div.brochure__container label {
  @apply block mt-[12px] mb-[6px] text-[12px] leading-[17px] font-[700] text-[#001151] tracking-[0.1em];
}

div.brochure__container .submit {
  @apply text-[#fff] border-[1px] border-[#001151] w-[105px] h-[32px] rounded-[90px] bg-[#001151] text-[16px];
}

/*forms__testdrive*/

.testdrive__container {
  @apply w-[300px] mx-auto flex flex-col sm:w-full;
}

.testdrive__container .testdrive__subtitle {
  @apply bg-[#EFFAFC] p-2 font-bold text-[#001151] text-[16px] leading-[19px];
}

.testdrive__container .testdrive__container1 {
  @apply bg-[#d9d9d9] flex flex-col p-4;
}

.testdrive__container .title {
  @apply text-center p-2 text-[24px] leading-[29px] text-[#001151] font-[700];
}

div.testdrive__container input[type="text"],
div.testdrive__container input[type="email"],
div.testdrive__container input[type="tel"],
div.testdrive__container input[type="date"],
div.testdrive__container select {
  @apply shadow-[inset_0px_4px_4px_rgba(0,0,0,0.25)] rounded-none block border-none outline-none px-1 text-[12px] w-[264px] sm:w-[240px] h-[32px];
}

div.testdrive__container label {
  @apply block mt-[12px] mb-[6px] text-[12px] leading-[15px] font-[700]  text-[#001151] tracking-[0.1em];
}

div.testdrive__container .submit {
  @apply text-[#fff] border-[1px] border-[#001151] w-[105px] h-[32px] rounded-[90px] bg-[#001151];
}

div.testdrive__container input[type="date"]::before {
  color: #999999;
  content: attr(placeholder);
}
div.testdrive__container input[type="date"] {
  color: #ffffff;
}
div.testdrive__container input[type="date"]:focus,
div.testdrive__container input[type="date"]:valid {
  color: #666666;
}
div.testdrive__container input[type="date"]:focus::before,
div.testdrive__container input[type="date"]:valid::before {
  content: "" !important;
}

/*end of forms*/

/*Start of calculate payment */

div.calculate__payment__form__container {
  @apply w-[300px] mx-auto mt-2  sm:w-full md:w-10/12 lg:w-7/12 px-[25px];
}

div.calculate__payment__form__container select {
  @apply block w-[250px] sm:w-[250px] h-[50px] border-[1px] border-[#C0C0C0] text-[14px] font-['SuzukiBold'];
}

div.calculate__payment__form__container label {
  @apply block mt-[12px] mb-[6px] text-[14px] leading-[21px] font-[700]  text-[#001151] tracking-[0.02em] font-['SuzukiRegular'];
}

div.calculate__payment__form__container .submit {
  @apply text-[#fff] border-[1px] border-[#001151] w-[142px] h-[40px] rounded-[90px] bg-[#001151] text-center mt-10 sm:mt-[0px] text-[13px] font-['SuzukiRegular'];
}
.result__box__container {
  @apply md:relative md:w-8/12 md:m-auto md:h-[300px]  md:border-[1px] md:border-[#D9D9D9] rounded-md p-4 md:ml-[200px];
}

.calculate__payment__result__container .button {
  @apply w-[150px] h-[40px] rounded-[90px] text-[13px] font-[SuzukiBold] font-[400] leading-[16px] tracking-[0.02em] bg-[#001151] text-inverted;
}

.calculate__payment__result__container .button__inverted {
  @apply w-[150px] h-[40px] rounded-[90px] text-[13px] font-[SuzukiBold] font-[400] leading-[16px] tracking-[0.02em] bg-[#ffffff] text-[#001151] border-[2px] border-[#001151];
}
/*End of calculate payment */

/* Get Qute*/

.get__quote__container {
  @apply w-full px-1 md:w-9/12 md:mx-auto;
}

div.get__quote__container label {
  @apply block mt-[12px] mb-[6px] text-[14px] leading-[21px] font-[400]  text-[#001151] tracking-[0.02em] font-['SuzukiRegular'] text-[#001151];
}

div.get__quote__container .customer__info input,
div.get__quote__container .customer__info select {
  @apply w-[330px] sm:w-[399px] h-[50px] rounded-none outline-none px-1 text-[14px] border-[1px] border-[#C0C0C0];
}

div.get__quote__container .customer__info input,
div.get__quote__container .customer__info select {
  @apply w-[330px] sm:w-[399px] h-[50px] rounded-none outline-none px-1 text-[14px] border-[1px] border-[#C0C0C0];
}

div.get__quote__container .preffered__option input,
div.get__quote__container .preffered__option select {
  @apply h-[50px] text-[14px] outline-none;
}

div.get__quote__container .preffered__option .grouping__border {
  @apply w-[300px] border-[1px] border-[#C0C0C0];
}

div.get__quote__container .preffered__option .grouping__border1 {
  @apply w-[300px] h-[50px] rounded-none outline-none px-1 text-[14px] border-[1px] border-[#C0C0C0];
}

div.get__quote__container .preffered__dealer input,
div.get__quote__container .preffered__dealer select {
  @apply w-[300px] h-[50px] rounded-none outline-none px-1 text-[14px] border-[1px] border-[#C0C0C0];
}

div.get__quote__container .finance__option input,
div.get__quote__container .finance__option select {
  @apply w-[300px] h-[50px] rounded-none outline-none px-1 text-[14px] border-[1px] border-[#C0C0C0];
}

div.get__quote__container .submit {
  @apply text-[#fff] border-[1px] border-[#001151] w-[171px] h-[40px] rounded-[90px] bg-[#001151] text-[13px];
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #110051;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.slider-button-change:active {
  background-color: #171c8f !important;
}

@media only screen and (max-width: 780px) and (min-width: 768px) {
  .container.mx-auto {
    padding-left: 16px;
  }

  .topics__container {
    padding-left: 16px;
  }
}

@media only screen and (max-width: 824px) and (min-width: 768px) {
  .banner_slide--right .banner_card .commonHomeCard__details {
    left: 0;
  }
}

@media (max-width: 1420px) {
  @media only screen and (max-width: 1420px) and (min-width: 768px) {
    .container-with-padding {
      padding-right: 60px;
    }
  }
}

button.slick-arrow {
  @apply absolute top-[-85px] right-[80px] text-transparent w-[43px] h-[43px] bg-primary-blue mx-[10px] my-0 rounded-md;
  font-size: 0;
}

button.slick-arrow.slick-prev {
  right: 120px !important;
  left: auto !important;
}

button.slick-arrow.slick-prev:after {
  content: url("/Vector-left.png");
  /* background-image: url('/Vector-left.png'); */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button.slick-arrow.slick-prev:before {
  content: "" !important;
}

button.slick-arrow.slick-next:before {
  content: "" !important;
}

button.slick-arrow.slick-next:after {
  content: url("/Vector-right.png");
  /* background-image: url('/Vector-left.png'); */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button.slick-arrow.slick-next {
  right: 65px !important;
}

button.slick-arrow.slick-disabled {
  @apply !bg-tab-color;
}

.news-class::after{
  /* position: absolute;
  content: '';
  border: solid 10px transparent;
  border-top-color: #fff;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%); */
  /* @apply absolute content-[""] border-[10px] border-transparent border-t-inverted bottom-[-20px] left-[50%] translate-x-[-50%] shadow-xl */
  
}

.info-icons:hover .news-class{
  @apply flex
}

@media (max-width: 767px) {
  button.slick-arrow.slick-next {
    right: 0px !important;
  }

  button.slick-arrow.slick-prev {
    right: 50px !important;
    left: auto !important;
  }
}

.brand-logo_container {
  @apply max-w-[120px] md:max-w-[130px] min-w-[120px] md:min-w-[130px] max-h-[23px] md:max-h-[25px] min-h-[23px] md:min-h-[23px];
}

/* @media (min-width: 768px) { */
.brand-logo_anniversary {
  min-height: 39px !important;
  min-width: 179px !important;
} 
/* } */
.brand-logo_container > div {
  position: unset !important;
}

.brand-logo_container .brand-logo {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-tabs .react-tabs__tab-list::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.topicSlide__modal {
  margin-top: 15vh !important;
  padding: 0 !important;
  max-width: 100vw !important;
}

.topicSlide__modal .react-responsive-modal-closeButton{
  border : solid 1px #fff;
  @apply outline-none bg-inverted
}

.layout__page--container {
  @apply min-h-screen;
}
video {
  object-fit: cover;
}

.fleetpriceList-wrapper
  .slick-slider.tabs-slide
  .slick-track
  .slick-slide.slick-active.slick-current {
  @apply md:border-b-4 md:text-primary-blue md:border-b-primary-blue;
}

.fleetpriceList-wrapper .slick-slider.content-slide button.slick-arrow {
  @apply hidden !important;
}

.fleetpriceList-wrapper .slick-slider.tabs-slide button.slick-arrow {
  @apply top-[35%] translate-y-[-50%] m-0 md:top-[-85px] lg:top-[-85px] rounded-[100%] md:rounded-md lg:rounded-md;
}

.fleetpriceList-wrapper .slick-slider.tabs-slide button.slick-arrow.slick-prev {
  @apply z-[1] md:left-auto md:right-[120px] right-auto left-0 !important;
}

@media only screen and (min-width: 1778px) {
  .locatorModal {
    @apply !left-[24%] translate-x-[-7px];
  }
}

@media only screen and (min-width: 2680px) {
  .locatorModal {
    @apply !left-[30%] translate-x-[10px];
  }
}

.select-heads-wrapper{
  /* box-shadow: 0px 4px 4px rgba(86,86,86,0.25); */
  @apply shadow-[0px_4px_4px_rgba(86,86,86,0.25)] bg-inverted absolute pointer-events-none w-full outline-0 flex items-center justify-center border-[2px] border-gray rounded-[2px] py-[8px] 
}

.select-heading{
  @apply md:text-section-heading lg:text-sub-heading text-section-heading uppercase font-suzukiBold mb-2
}

.select-subHead{
  @apply text-default uppercase m-0
}

.compareItem-wrap{
  @apply relative
}

.select-icon{
  @apply mx-3 my-2
}

/* quick changes */
.compareItem:nth-child(2)
  .compareItem_imageDetails
  .car_selecBox--listboxOptions {
  @apply right-0 md:right-[auto];
}
.threeColumnRadius .commonHomeCard__image img {
  @apply rounded-md;
}

.react-datetime-picker__wrapper {
  @apply border-none;
}

.react-datetime-picker__inputGroup__input:invalid{
  @apply bg-inverted 
}

input.react-datetime-picker__inputGroup__input:focus-visible{
  @apply border-none outline-none
}

.react-tooltip{
  max-width: 100%;
}

input[type="radio"]:checked + label::before {
  border-color: blue !important;
  border: 1px solid red;
}

button.react-calendar__tile.react-calendar__tile--active {
  @apply bg-primary-blue text-inverted
}

.form-modal__container {
  width: 85vw;
}

.selects-heading{
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.submenu-selected::after {
  content: "";
  display: block;
  width: 85%;
  height: 0.15rem;
  margin-left: auto;
  margin-right: auto;
  background: #00000038;
}

.submenu-selected:before {
    content: "";
    display: block;
    width: 85%;
    height: 0.15rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #001151;
}


.react-responsive-modal-modal.form-modal__container{
  @apply w-[85vw] lg:w-full
}

.form-consent-field{
  @apply relative
}


/* .form-consent-field p {
  @apply absolute left-0 m-0
} */

/* HISTORY CSS */

/* .history-1970s:after {
  position: absolute;
  content: '';
  left: 40px;
  width: 2px;
  height: 100%;
  background: #A7A7A7;
  top: 0;
} */

.history-1970s:after{
  @apply md:absolute md:content-[''] md:left-[40px] md:w-[2px] md:h-full md:bg-[#A7A7A7] md:top-0
}

/* .history-model-heads:after {
  position: absolute;
  content: '';
  top: 50%;
  width: 42px;
  height: 2px;
  background: #A7A7A7;
  left: -60px;
  transform: translateY(-50%);
} */

.history-model-heads:after {
  @apply md:absolute md:content-[''] md:top-[50%] md:w-[42px] md:h-[2px] md:bg-[#A7A7A7] md:left-[-60px] md:translate-y-[-50%]
}

/* .active-scroll-spy {
  @apply rounded-[15px] text-[red] font-suzukiBold
} */

.history-lists::-webkit-scrollbar{
  display: none;
}

.nav-anchor.active {
  @apply text-primary-blue border-b-primary-blue border-b-[3px]
}
/* Cookie Consent Style */

header#ensTitle{
  @apply text-defaut-color font-suzukiBold
}

.consentDescription{
  @apply mb-[20px]
}

.ensToggleRow{
  @apply py-[10px] mb-0 !important 
}

.ensToggleLabel .card{
  @apply top-[50%] translate-y-[10%] md:translate-y-[100%]
}

#ensModalWrapper .ensModal{
  @apply mt-[20px] pt-[7px]
}

#ensModalWrapper{
  @apply left-[33px] md:left-[90px] lg:left-[90px] max-w-[85%] !important
}

.ensModal .description{
  @apply mt-[3px] !important
}
/* Cookie Consent End */

@media only screen and (max-width: 1350px) and (min-width: 1250px){
  .compare_drops {
    @apply pr-[60px] !important
}
}

@media only screen and (max-width: 1200px) and (min-width: 1024px){
.compare_drops {
  @apply pr-[50px] !important
}
}

@media only screen and (max-width: 1300px) and (min-width: 1245px){
  .compare_drops {
    @apply pr-[90px] !important
  }
  }

@media only screen and (max-width: 1245px) and (min-width: 1196px)  {
.compareItem__selectbox--fixed {
    max-width: calc(100vw / 4 - 80px);
}
}

.configurator-scroll::-webkit-scrollbar {
  display: none;
}
.variant-scroll::-webkit-scrollbar {
  display: none;
}
.bg-g {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 10px;
}

.bg-g:after{    
  content: '';    
  width: 100%;    
  height: 100%;    
  position: absolute;    
  border-radius: 50%;  
  background: linear-gradient(270deg,rgba(252,255,255,0)0%,rgba(255,255,255,0.5)50%,rgba(255,255,255,0)101%);
}
/* .consent-custom span::after{
  @apply 
} */

.consent-custom input[type=radio]:checked + span{
  @apply border-primary-blue 
}

.consent-custom input[type=checkbox]:checked + span{
  @apply border-primary-blue border-[3px]
}

.consent-custom input[type=radio]:checked + span:after {
  @apply bg-primary-blue absolute content-[''] w-[40%] h-[40%] rounded-full top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] 
}

.consent-custom input[type=checkbox]:checked + span:after {
  position: absolute;
  content: '';
  background-image: url(/consentCheck.svg);
  background-size: 8px; 
  background-repeat: no-repeat;
  background-position: center;
  inset: 0;
}

.consent-custom-grey input[type=checkbox]:checked + span:after{
  position: absolute;
  content: '';
  background-image: url(/tick.svg);
  background-size: 8px; 
  background-repeat: no-repeat;
  background-position: center;
  inset: 0;
}

.consent-custom-grey input[type=checkbox]:checked + span{
  @apply border-[#C0C0C0] border-[3px]
}

.verification-input:after {
  @apply absolute content-[''] w-[12%] bg-tab-color h-[2px] bottom-[12px] left-[50%] translate-x-[-50%]
}

.ensModal .card{
  outline: none!important;
}

.ensModal .card:focus{
  outline: none!important;
}

section#ensConsentWidget{
  @apply left-[10px] right-auto bottom-[100px] md:left-auto md:right-[10%] md:bottom-0 lg:left-auto lg:right-[10%] lg:bottom-0 !important 
}

section#ensModalWrapper{
  @apply max-h-[96%] overflow-y-scroll left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] md:w-[1150px] w-[352px] !important
}

.no-scroll {
  height: 100vh;
  overflow: hidden !important;
}

.testimonial-box{
  box-shadow: 0px 0px 14px rgb(108 109 110 / 16%);
}
@supports (-webkit-touch-callout: none) {
  .input[type="date"] {
      @apply text-left !important
  }

  .input[type="date"] {
    text-align: left;
  }

  .input:focus{
    @apply text-left
  }

  .input:focus-visible{
    @apply text-left
  }
}
@media only screen and (max-width: 820px) {
  .unreset-allComp table{
    table-layout: auto !important;
    width: 100% !important;
  }
}





/*.image-slider__slider-item{*/
/*  display: flex !important;*/
/*  border-radius: 5px !important;*/
/*  max-width: 50vw !important;*/
/*}*/

/*.image-slider__slider-dots{*/
/*  display: flex !important;*/
/*  justify-content: center;*/
/*  gap:5px;*/
/*  margin-top: 18px;*/
/*}*/


.image-slider__slider-image{
  width: 60%;
  display: flex;
}

.image-slider__slider-content{
width: 40%;
text-align: left;
padding: 20px;
overflow-y: auto
}
/*.image-slider__slider-dialog button{*/
/*  @apply top-[50%] translate-y-[-50%]*/
/*}*/

/*.image-slider__slider-dialog button.slick-arrow.slick-prev{*/
/*  z-index: 10;*/
/*  left: 110px !important;*/
/*  border-radius: 0;*/
/*  @apply bg-primary-blue;*/
/*}*/

/*.image-slider__slider-dialog button.slick-arrow.slick-next{*/
/*  @apply rounded-none bg-primary-blue right-[110px] z-10 !important*/
/*}*/

.swiper-button-prev{
/* content: url("../public/slideArrowBack.svg") !important; */
@apply w-[40px] h-[40px] bg-primary-blue border-none !important;
}

.swiper-button-next{
/* content: url("../public/slideArrowBack.svg") !important; */
@apply w-[40px] h-[40px] bg-primary-blue border-none  !important;
}

.swiper-button-prev::after {
content: "" !important;
background-image: url("../public/slideArrowBack.svg");
background-size: 10px;
color: white;
position: absolute;
font-size: 20px!important;
inset: 0;
background-repeat: no-repeat;
background-position: center;
}

.swiper-button-next::after {
content: "" !important;
background-image: url("../public/slideArrowFront.svg");
background-size: 10px;
color: white;
position: absolute;
font-size: 20px!important;
inset: 0;
background-repeat: no-repeat;
background-position: center;
}

.swiper-pagination-bullet{
@apply w-[12px] h-[12px] bg-gray opacity-[1] !important
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active{
@apply bg-inverted opacity-[1] !important
}

.image_gallery_modal-overlay{
background: rgba(0, 0, 0, 0.70) !important;
}

.swiper-slide {
border-radius: 5px;
overflow: hidden;
}

.swiper-slide:after {
position: absolute;
content: "";
inset: 0;
background-color: rgba(83,83,83,.7);
box-shadow: 0 4px 4px rgba(0,0,0,.25);
}

.swiper-slide.swiper-slide-active:after {
position: static;
}

.image_gallery_modal-container .swiper.swiper-initialized {
height: 360px;
width: 820px;
}

.image_gallery_modal-container .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal{
/* position: fixed !important; */
left: 30px;
bottom:10px;
}

/* .swiper.swiper-initialized.swiper-horizontal.slider.swiper-backface-hidden {
position: static;
}  */

/*.image-slider__slider-dialog button.slick-arrow.slick-disabled{*/
/*  @apply rounded-none bg-gray !important;*/
/*}*/

/*.image-slider__slider-dialog button.slick-arrow.slick-next:after{*/
/*  content: url("../public/slideArrowFront.svg") !important*/
/*}*/

/*.image-slider__slider-dialog .slick-slide{*/
/*  !* box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25); *!*/
/*  background-image: url("/galleryBg.png");*/
/*  @apply mx-[20px] rounded-[5px] overflow-hidden*/
/*}*/

/*.image-slider__slider-item{*/
/*  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);*/
/*  @apply bg-[rgba(83,83,83,0.80)] relative*/
/*}*/

/*.image-slider__slider-item::after{*/
/*  position: absolute;*/
/*  content: '';*/
/*  inset: 0;*/
/*  background-color: rgba(83, 83, 83, 0.70);*/
/*  box-shadow: 0px 4px 4px rgb(0, 0, 0, 0.25);*/
/*}*/

/*.image-slider__slider-dialog .slick-slide.slick-active.slick-center.slick-current .image-slider__slider-item::after{*/
/*  position: static;*/
/*}*/

/*.image-slider__slider-dialog .slick-slide.slick-active.slick-center.slick-current .image-slider__slider-item{*/
/*  box-shadow: none;*/
/*  @apply bg-transparent*/
/*}*/

/*.image-slider__slider-dialog .slick-slide.slick-active.slick-center.slick-current{*/
/*  @apply bg-inverted shadow-none bg-none !important*/
/*}*/


/*.image-slider__slider-dialog button.slick-arrow{*/
/*  opacity: 0;*/
/*  visibility: hidden;*/
/*}*/

/*.image-slider__slider-dialog .slick-slider.slick-initialized:hover button.slick-arrow{*/
/*  opacity: 1;*/
/*  visibility: visible;*/
/*}*/

.image_gallery_modal-container {
min-width: 90vw;
border: none;
overflow:hidden;
background: transparent;
box-shadow: none;
}
.image_gallery_modal-container:focus-visible{
border: none !important;
outline: none;
}
 



.sliderinput::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #001151;
  cursor: pointer;
  border-radius: 50%;
}

.range-line , .range-line2 , .range-line3{
  position: absolute;
  top: 50%;
   transform: translateY(-50%);
  height: 2px;
  background-color: #001151;
  z-index: 0;
  border-radius: 50px;
  overflow: hidden;
}

/* .slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
} */

.App {
  font-family: sans-serif;
}
.configurator-modal {
  width: fit-content;
  border-radius: 12px;
  background: #D9D9D9;
  overflow-y: visible;
}

.configurator-overlay {
  background: rgba(0, 0, 0, 0.80);
}

.config-circle-container{
  display: grid;
  place-items: center;
}

.dropdown {
  position: relative;
  color: #333;
  cursor: default;
}

/* .dropdown .arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: " ";
  display: block;
  height: 0;
  margin-top: 0.3rem;
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.dropdown .arrow.open {
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
} */

.dropdown .selected-value input {
  line-height: 1.5;
  font-size: 1rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  padding: 8px 52px 8px 10px;
  transition: all 200ms ease;
  width: 100%;
}

.dropdown .options {
  display: none;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
}

.dropdown .options.open {
  display: block;
}

.dropdown .option {
  /* box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block; */
  /* padding: 8px 10px; */
  font-size: 14px;
  color: black;
  padding: 0px 5px 0px 14px;
}

.dropdown .option.selected,
.dropdown .option:hover {
  background-color: #f2f9fc;
  color: #333;
}



/*


.range-slider-container {
  position: relative;
}

.custom-range {
  width: 100%;
  height: 10px;
  -webkit-appearance: none;
  background: linear-gradient(to right, #ccc, #ccc);
  border-radius: 5px;
  outline: none;
  margin: 0;
  padding: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #3498db;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  &::-webkit-slider-runnable-track {
    height: 10px;
    background: transparent;
    position: relative;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: none;
    border-radius: 50%;
    background-color: #3498db;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  &::-moz-range-track {
    height: 10px;
    background: transparent;
    position: relative;
  }

  &::-ms-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #3498db;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  &::-ms-track {
    height: 10px;
    background: transparent;
    position: relative;
  }

  .sliderinput::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: repeating-linear-gradient(to right, #3498db, #3498db 1px, transparent 1px, transparent 12px);
  }
} */


.vertical-line {
  @apply absolute bg-[#C0C0C0] w-[2px] h-full z-[-1];
  /* left: calc(16.1% - 1px) !important; */
}


@media(min-width: 1480px) {
  .vertical-line.line-1 {
    @apply left-[calc(15.5%)] !important;
  }
}

@media(min-width: 1650px) {
  .vertical-line.line-1 {
    @apply left-[calc(15%)] !important;
  }

  .vertical-line.line-4 {
    @apply left-[calc(56.7%)] !important;
  }

  .vertical-line.line-5 {
    @apply left-[calc(70.4%)] !important;
  }

  .vertical-line.line-6 {
    @apply left-[calc(84.4%)] !important;
  }
} 

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.fieldIcon {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  z-index: 0;
  max-height: 18px;
  pointer-events: none;
}

.commonHomeCard__details--desc p{
  margin-bottom: 0;
}

.display_link{
  color: blue;
  text-decoration: underline;
}

.longdec{
  display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    font-weight: 500;
}

.selected-option {
  font-family: SuzukiRegular, sans-serif;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding-right: 15px;
  background-image: url(/_next/static/media/Vector.af41969d.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  background-size: 10px;
  background-color: transparent;
  display: block;
  height: 50px;
  min-width: 100%;
  max-width: 100%;
  border-radius: 0px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(192 192 192 / var(--tw-border-opacity));
  padding-left: 16px;
  padding-right: 26px;
  font-size: 14px;
  line-height: 50px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  cursor: default;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis; 
  user-select: none;
}

.selected-option:focus {
  outline: 1px solid #000;
}
.label-text {
  margin-bottom: 6px;
  display: block;
  text-align: start;
  font-family: 'SuzukiRegular';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.02em;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.custom-dropdown {
  position: relative;
}

.dropdown-options {
  width: 100%;
  cursor: default;
  position: absolute;
  top: 77px;
  font-size: 14px;
  font-family: SuzukiRegular, sans-serif;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  overflow-y: auto;
  max-height: 200px;
  user-select: none;
  background-color: #fff;
  z-index: 999;
}

/* .dropdown-options {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */

.dropdown-options .option-default:hover {
  background-color: rgb(67, 46, 253);
  color: #fff;
}

.dropdown-options .option:hover {
  background-color: rgb(67, 46, 253);
  color: #fff;
}

.dropdown-options .option-default {
  padding: 0px 10px;
}

.dropdown-options .option {
  padding: 10px 10px;
}

.error {
  @apply text-[#FF0000] text-[12px];
}

.select-border {
  border: 1px solid #000;
}

.dave-cb-icon{
  margin-top: 250px !important;
}
/* 
@media screen and (max-width: 1036px) {
  .dave-cb-icon {
    margin-top: 0px !important;
    margin-bottom: 58px !important;
    margin-left: 100% !important;
  }

  .mdwSliders ul.slick-dots {
    display: flex!important;
    justify-content: center;
    margin-top: 10px;
  }
  
  .mdwSliders ul.slick-dots li{
    margin: 0 2px;
  }
  
  .mdwSliders ul.slick-dots li button{
    @apply text-[0px] bg-gray h-[3px] w-[30px]
  }
  
  .mdwSliders ul.slick-dots li.slick-active button{
    @apply bg-primary-blue
  }
} */
 
@media screen and (max-width: 1036px) {
  .dave-cb-icon {
    margin-top: 0px !important;
    margin-bottom: 58px !important;
    margin-left: 100% !important;
  }
}

  .mdwSliders ul.slick-dots {
    display: flex!important;
    justify-content: center;
    margin-top: 10px;
  }
  
  .mdwSliders ul.slick-dots li{
    margin: 0 2px;
  }
  
  .mdwSliders ul.slick-dots li button{
    @apply text-[0px] bg-gray h-[3px] w-[30px]
  }
  
  .mdwSliders ul.slick-dots li.slick-active button{
    @apply bg-primary-blue
  }



.swiper-model:after {
  position: relative;
  background-color: transparent !important;
  box-shadow: transparent !important;
}

.backToTop__btn2 {
  @apply fixed  md:bottom-[60px] bottom-[15px] flex items-center justify-center md:right-[30px] right-[10px] md:block;
  }

  .pdf-container {
    width: 250px; 
    height: 250x;
  }
  
  .pdf-thumbnail {
    display: block;
    width: 250px;
    height: 315px;
    cursor: pointer;
    border-radius: 0.4rem;
  }
  
  .pdf-container:hover .pdf-thumbnail {
    cursor: pointer; 
  }
  
  .custom-left-arrow,
   .custom-right-arrow {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     transform: translateY(-175%);
     background-color: rgba(157, 179, 204, 0.5);
     color: rgb(25, 64, 101);
     border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 40px;
    cursor: pointer;
    z-index: 10;
    display: flex; /* Use flexbox */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    line-height: 1; /* Ensure no extra space inside */
  }
  
  .custom-left-arrow {
    left: -0.1px;
  }
  
  .custom-right-arrow {
    right: -0.1px;
  }
  
  .custom-left-arrow:hover,
  .custom-right-arrow:hover {
    background-color: rgba(95, 119, 141, 0.8);
  }
  
  
  .cta-container {
    position: relative; 
    margin-top: 4px;
  }
  
  .custom-line {
    margin-top: 10px; 
    border: none;
    height: 1px; 
    background-color: #aeabab;
    width: 100%;
    transition: background-color 0.3s ease; 
  }
  
  .card-btn {
    color: rgb(0 17 81 );
    font-size: 14px; 
    text-decoration: none; 
    transition: color 0.3s ease; 
    font-family: SuzukiBold, sans-serif;
  }
  
  .pdf-icon-container {
    display: flex;
    align-items: center;
    margin-top: 10px; /* Adjust based on your layout */
  }
  
  .pdf-icon {
    font-size: 10px; /* Adjust the size of the icon */
    color: #000; /* Adjust the color */
    width:100px;
    height:100px;
    margin-left: 100px;
    margin-bottom: 5px;
  }
  
  .scrollbar-hidden::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
  
  .scrollbar-hidden {
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    scrollbar-width: none; /* For Firefox */
  }

  .icon-filter {
    width: 18px;
    height: 18px;
    background-image: url('/filter_list.png'); /* Replace with the URL of your icon */
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
  }

  .advantage-desc{
    word-wrap: break-word;
  }

  .grid-cols-new-14{
      grid-template-columns: repeat(14, minmax(0, 1fr));
  }