.testdrive__container {
  @apply w-full w-[100%] min-w-full sm:w-[399px] md:w-[100%] outline-0 outline-none;
}

div.testdrive__container .testdrive__subtitle {
  @apply bg-[#EFFAFC] p-2 font-bold text-[#001151] text-[16px] leading-[19px];
}

div.testdrive__container .testdrive__container1 {
  @apply flex flex-col mx-auto w-full;
}

div.testdrive__container .title {
  @apply text-[24px] leading-[30px] font-['SuzukiBold'];
}

div.testdrive__container .submit {
  @apply text-[#fff] border-[1px] border-[#001151] w-[105px] h-[32px] rounded-[90px] bg-[#001151] text-[13px];
}

div.testdrive__container input[type="date"]::before {
    color: #000000b2;
    content: attr(placeholder);
    position: absolute;
}

div.testdrive__container input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-color: #fff;
    color: #ffffff;
    text-align: left !important;
}

div.testdrive__container input[type="date"]:focus,
div.testdrive__container input[type="date"]:valid {
    color: #000;
    background-color: #ffffff !important;
    text-align: left !important;
}

div.testdrive__container input[type="date"]:focus::before,
div.testdrive__container input[type="date"]:valid::before {
  content: "" !important;
}

/*end of forms*/
