@tailwind components;

@layer components {
  .container-fluid {
    @apply mx-auto max-w-screen-4xl;
  }

  .row {
    @apply flex flex-wrap w-full;
  }

  .col {
    @apply flex-1;
  }

  .col_1 {
    @apply w-1/12;
  }

  .col_2 {
    @apply w-2/12;
  }

  .col_3 {
    @apply w-3/12;
  }

  .col_4 {
    @apply w-4/12;
  }

  .col_5 {
    @apply w-5/12;
  }

  .col_6 {
    @apply w-6/12;
  }

  .col_7 {
    @apply w-7/12;
  }

  .col_8 {
    @apply w-8/12;
  }

  .col_9 {
    @apply w-9/12;
  }

  .col_10 {
    @apply w-10/12;
  }

  .col_11 {
    @apply w-11/12;
  }

  .col_12 {
    @apply w-full;
  }

  .toolTip_class {
    @apply hidden flex justify-between py-[8px] px-0 pr-[2px] md:pr-0 lg:pr-0 items-center absolute md:min-w-[420px] lg:min-w-[420px] w-full bg-inverted z-[2] border-[1px] border-[#E0E0E0] shadow-xl rounded-[6px] after:absolute after:content-[""] after:border-[10px] after:border-transparent after:border-t-inverted after:shadow-xl;
  }

  .tool_grid_position {
    @apply md:top-[-65px] lg:top-[-65px] top-[-75px] left-[10px];
  }

  .tool_afters_secondary {
    @apply after:bottom-[-20px] after:left-auto after:right-0 after:translate-x-[-50%];
  }

  .tool_grid_afters {
    @apply after:bottom-[-20px] md:after:left-[50%] lg:after:left-[50%] after:left-auto after:right-0 md:after:right-auto after:translate-x-[-50%];
  }

  .toolTip_desc {
    @apply w-[92%] m-0 text-[12px] leading-[16px] font-suzukiRegular;
  }
}
